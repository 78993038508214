import {useMemo} from 'react';

export interface UseUploadWidgetProps {
  options?: Record<string, number | string | boolean>;
  onUpload: (id: string) => void;
}

export const useUploadWidget = ({options, onUpload}: UseUploadWidgetProps) => {
  const widget = useMemo(
    () => () => {
      const myUploadWidget = openUploadWidget(
        {
          cloudName: 'dogz7twzq',
          uploadPreset: 'expert_assets',
          tags: ['lander'],
          sources: ['local', 'url', 'camera', 'google_drive'],
          multiple: false,
          maxVideoFileSize: 30000000,
          showPoweredBy: false,
          ...options,
        },
        function (error, result) {
          if (!error && result.event === 'success') {
            const url = getVideoUrl(result.info.url.replace('http:', 'https:'));
            onUpload(url);
          }
        },
      );
      myUploadWidget.open();
    },
    [onUpload, options],
  );

  return widget;
};

const root = `https://res.cloudinary.com/dogz7twzq/video/upload/`;
function getHasCloudinaryRoot(src: string) {
  return src.includes(root);
}

function getLastPathFromUrl(url: string) {
  const parts = url.split('/');
  return parts[parts.length - 1];
}

function getVideoUrl(src: string) {
  let url: string;
  if (getHasCloudinaryRoot(src)) {
    url = `${root}q_75/${getLastPathFromUrl(src)}`;

    // Use Cloudinary's dynamic URL video transformation to get a mp4 file for uploaded mov files.
    const urlParts = url.split('.');
    const fileExt = urlParts.pop();
    if (fileExt?.toLowerCase() === 'mov') {
      urlParts.push('mp4');
    } else if (fileExt !== undefined) {
      urlParts.push(fileExt);
    }
    url = urlParts.join('.');
  } else {
    url = src;
  }

  return url;
}

const imageRoot = `https://res.cloudinary.com/dogz7twzq/image/upload/`;
export function splitCloudinaryCropImageUrl(src: string) {
  if (getHasCloudinaryImageRoot(src)) {
    const parts = src.split(imageRoot);
    const asset = parts[1];
    return `${imageRoot}c_crop,g_custom/${asset}`;
  }
  return src;
}

function getHasCloudinaryImageRoot(src: string) {
  return src.includes(imageRoot);
}

export const openUploadWidget = (options, callback) => {
  return window.cloudinary.openUploadWidget(options, callback);
};
